@import '../../../style/variables', '../../../style/functions', '../../../style/engage/variables';

$root: '.greeting-splash-screen';

#{$root} {
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 1001;
  pointer-events: none;

  @media screen and (min-width: $bp-tablet-portrait) {
    width: 32.2em !important;
  }

  &_bg-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $secondary-bg-color;

    &.welcome {
      background-color: $primary-theme-color;
    }

    &.feedback {
      background-color: $engage-theme-color;
    }

    img.bg-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_content {
    font-size: 1.14rem;
    color: $primary-text-color;
    font-weight: bold;
    line-height: 1.43;
    text-align: center;
    position: absolute;
    height: fit-content;
    width: 100%;
    padding: 0 16%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    top: goldenRatioOffset(100%);
    &.welcome {
      color: $primary-theme-complementing-text-color;
      text-shadow: 0 1px 0.75em $primary-dropshadow-color;
    }
    &.feedback {
      color: $engage-tertiary-text-color;
      text-shadow: 0 1px 0.75em $engage-primary-dropshadow-color;
    }
    .guest-name {
      font-size: 1.71rem;
    }

    &_greeting-text {
      &_loader {
        position: relative;
        margin-top: 1.71em;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
      }
    }
  }
}
