@import '../../style/variables';

.SpinnerOverlay__div {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  color: $primary-bg-color;
  background: $primary-text-color;
  z-index: 1000;
  opacity: 0.5;
}

.Spinner {
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

@media only screen and (min-width: $bp-tablet-portrait) {
  .SpinnerOverlay__div {
    width: inherit;
    left: auto;
  }
}
