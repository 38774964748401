$default-font: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
$default-text-color: rgba(0, 0, 0, 0.87);
$default-box-shadow-color: rgba(0, 0, 0, 0.2);
$red: #d0021b;
$green: #6ab11c;
$black: #303030;
$yellow: #f5a623;
$blue: #4a90e2;

/* Theme-specific typography */
$primary-theme-color: #547aa8;
$primary-theme-complementing-text-color: #ffffff;
//$primary-theme-color: #987039;
$primary-bg-color: #ffffff;
$secondary-bg-color: #fafafa;
$tertiary-bg-color: #f5f5f5;
$quaternary-bg-color: #e0e0e0;
$quinary-bg-color: rgba(84, 122, 168, 0.1);
$senary-bg-color: #1e1e1e;
$primary-text-color: #303030;
$secondary-text-color: #424242;
$tertiary-text-color: #417505;
$quaternary-text-color: #ffffff;
$heading-text-color: #616161;
$placeholder-text-color: #bdbdbd;
$descriptor-text-color: #757575;
$dim-text-color: rgba(0, 0, 0, 0.54);
$button-text-color: #ffffff;
$outline-button-bg-color: #ffffff;
$primary-dropshadow-color: rgba(0, 0, 0, 0.5);
$error-color: #f0340c;
$disabled-text-color: #bdbdbd;
$action-button-text-color: #4a90e2;
$nonFudr-qr-warning-color: rgba(218, 47, 47, 0.8);
//$action-button-text-color: #987039;
$line-seperator: #d8d8d8;
$line-seperator-dim-color: #bdbdbd;
$offer-card-bg-color: rgba(84, 122, 168, 0.5);
$card-bg-color: #f1f7ff;
$exclusive-offer-card-bg-color: #fdedee;
$exclusive-offer-card-border-color: #dc666a;
$exclusive-offer-card-text-color: #dc666a;
$exclusive-offer-ribbon-color: #dc666a;
$scan-qr-background-color: rgba(0, 0, 0, 0.58);
$toast-bg-color: rgba(0, 0, 0, 0.54);
$ribbon-color: #777777;
$ribbon-text-color: #ffffff;
$discount-text-color: #6ab11c;
$upsell-item-card-bg-color: #ffffff;
$upsell-item-heading-color: #ffffff;
$primary-mask-gradient: linear-gradient(110deg, rgba(0, 0, 0, 0.8) 2%, rgba(0, 0, 0, 0.1) 99%);
$secondary-mask-gradient: linear-gradient(110deg, rgba(0, 0, 0, 0.2) 2%, rgba(0, 0, 0, 0.7) 99%);
$cash-card-preview-text-color: rgba(245, 245, 245, 0.8);
$recharge-button-bg-color: rgba(0, 0, 0, 0.5);
$open-input-underline-color: #bdbdbd;
$amount-stub-color: $primary-theme-color;
$swipe-button-bg-color: $primary-theme-color;
$swipe-button-border-width: 4px;
$swipe-button-caret-shadow-color: rgba(0, 0, 0, 0.2);
$layout-container-padding: 1.14em;
$popup-padding: 1.71em;
$dark-forward-navigation-button-color: rgba(0, 0, 0, 0.5);
$warning-color: #d0021b;
$cash-card-banner-height: 2.57em;
$disabled-check-opacity: 0.5;
$offer-stub-color: #b149c7;
$card-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.5);
$overlay-bg-color: rgba(0, 0, 0, 0.4);
$input-bg-color: #f5f5f5;
$error-bg-color: #f6ebe8;
$caution-color: #e69417;
$caution-bg-color: #fff8ed;
$primary-helper-text-color: #7c7c7c;
$input-field-box-shadow: rgba(12, 27, 45, 0.3);
$popup-box-shadow: 0 -2px 6px 0 rgba(34, 36, 38, 0.15);

/* Breakpoints */
$bp-mobile-sm: 320px;
$bp-mobile-md: 360px;
$bp-mobile-lg: 414px;
$bp-tablet-portrait: 769px;
$bp-tablet-landscape: 1024px;

/* Device heights */
$device-height-xs: 568px;
$device-height-sm: 667px;
$device-height-md: 736px;
$device-height-lg: 812px;

$regular-font: 'OpenSans-Regular';
$bold-font: 'OpenSans-Bold';
$semi-bold-font: 'OpenSans-SemiBold';
$extra-bold-font: 'OpenSans-ExtraBold';
