$root: '.default-page';

#{$root} {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .fudr-loading-animation {
    width: 50%;
  }
}
