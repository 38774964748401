@import '../style/variables', '../style/engage/variables';

$root: '.container';

#{$root} {
  width: 100%;
  height: 100%;
  @media only screen and (min-width: $bp-tablet-portrait) {
    width: 32.21em;
    top: 0;
    bottom: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: auto;

    &.page {
      width: 100%;
      margin: unset;
      box-shadow: unset;
    }

    button a {
      cursor: pointer;
    }
  }

  &.engage {
    a {
      cursor: pointer;
      color: $engage-theme-color !important;
      text-decoration: none !important;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

$landscape-not-supported-container: '.landscape-not-supported-guide';

#{$landscape-not-supported-container} {
  position: fixed;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  display: none;
  z-index: 9999; // A very high z-index would trump all other layers

  &.visible:not(.ios) {
    display: block;
  }

  // We use CSS-detected device orientation for iOS since it does not support the ScreenOrientation Web API. See https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation.
  &.ios {
    @media (orientation: landscape) and (max-height: $device-height-xs) {
      display: block;
    }
  }

  .landscape-placeholder-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}
