// Engage - styling variables

// ----------------------------

// Color scheme

// Elementary (thematic)
$engage-theme-color: #eb9658;
$engage-theme-color--light-50: #f5be95;
$engage-theme-color--light-25: #fde0ca;
$engage-theme-color--light-10: #ffeee1;
$engage-primary-bg-color: #fafafa;
$engage-secondary-bg-color: #ffffff;
$engage-tertiary-bg-color: #f0f0f0;
$engage-primary-text-color: #1e1e1e;
$engage-secondary-text-color: #7c7c7c;
$engage-tertiary-text-color: #ffffff;
$engage-divider-bg-color: #9c9c9c;
$engage-primary-border-color: #9c9c9c;
$engage-primary-dropshadow-color: rgba(0, 0, 0, 0.05);
$engage-helper-text-color: #7c7c7c;
$success-color: #37d3ae;
$success-colo--light-75: #68dec2;
$success-color--light-50: #9ae9d6;
$success-color--light-25: #ccf4ea;
$error-color: #d03131;
$error-color--light-75: #dc6464;
$error-color--light-50: #f9c9c9;
$error-color--light-25: #fff5f5;
$demo-banner-bg-color: #547aa8;
$feedback-theme-color:#EDA749;

// Library component-specific
// Button
// $button-bg-color:
// $button-text-color:
// $button-bg-color--outline:
// $button-text-color--outline:
// $button-bg-color--flat:
// $button-text-color--flat:
// Textfield
// $textfield-bg-color:
// $textfield-border-color:
// $textfield-focus-border-color:
// $textfield-error-bg-color:
// $textfield-error-border-color:
// $textfield-error-focus-border-color:

// Business component-specific
$deal-default-bg-color: #f2c94c;

// ----------------------------

// Breakpoints
$bp-mobile-sm: 320px;
$bp-mobile-md: 360px;
$bp-mobile-lg: 414px;
$bp-tablet-portrait: 769px;
$bp-tablet-landscape: 1024px;

// Device heights
$device-height-xs: 568px;
$device-height-sm: 667px;
$device-height-md: 736px;
$device-height-lg: 812px;

// ----------------------------
